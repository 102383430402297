import { Link } from 'gatsby-plugin-intl'
import React, { FC } from 'react'

interface IImage {
  fullpath: string
  metadata: IMetaDataItem[]
}
interface IMetaDataItem {
  name: string
  data: string
}

interface IFactItem {
  icon: string
  label: string
}

interface IInternalLink {
  slug: string
  absolutePath: string
}

interface IContent {
  brand: IImage
  image: IImage
  text: string
  headline: string
  backgroundColor: string
  facts: IFactItem[]
  buttonLinkExternal: string
  buttonText: string
  buttonLink: IInternalLink
  imageIsRight: boolean
}

export const ImageTextCard: FC<{ block: IContent }> = ({ block }) => {
  console.log(block)
  return (
    <div className={`image-text-card is--background-${block.backgroundColor} ${block.imageIsRight ? 'is--image-right' : 'is--image-left'}`}>
      <div className="content-column">
        <h2 className="image-text-card__headline paragraph-headline">
          {block.headline}
        </h2>
        <div className="image-text-card__text" dangerouslySetInnerHTML={{ __html: block.text }}></div>
        {block.brand &&
          <img alt={getAlt(block.brand)} className="image-text-card__brand" src={block.brand.fullpath} />
        }
        {block.facts?.length > 0 &&
          <div className="image-text-card__fact-list">
            {block.facts.map((fact, i: number) => (
              <div className="fact-item" key={`fact${i}`}>
                <i className={`fact-item__icon fa ${fact.icon}`}></i>
                <p className="fact-item__label">
                  {fact.label}
                </p>
              </div>
            ))}
          </div>
        }
        {block.buttonText &&
          <Link className={`image-text-card__link ${block.facts?.length > 0 ? 'has--facts' : ''}`} to={block.buttonLink ? block.buttonLink.absolutePath : block.buttonLinkExternal}>
            {block.buttonText} <i className="fa fa-arrow-right ml-2"></i>
          </Link>
        }
      </div>
      <div className="image-column">
        {block.image &&
          <img alt={block.headline} src={block.image.fullpath} />
        }
      </div>
    </div>
  )
}

function getAlt (image: IImage) {
  if (image.metadata) {
    const item = image.metadata.find(element => element.name === 'alt')

    return item ? item.data : undefined
  }

  return undefined
}
