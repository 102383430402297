import { Link } from 'gatsby'
import React, { FC } from 'react'

interface IImage {
  fullpath: string
}

interface IButtonLink {
  slug: string
}

interface ILink {
  id: number
  absolutePath: string
  slug: string
}
interface IContent {
  headline: string
  text: string
  buttonLink: IButtonLink
  buttonText: string
  image: IImage
  showTeaser: boolean
  teaserLabel: string
  teaserHeadline: string
  teaserText: string
  teaserTarget: ILink
}

export const Hero: FC<{ block: IContent }> = ({ block }) => {
  console.log(block)
  return (
    <>
      {block.image &&
        <div className="hero row no-gutters">
          <i className="phone-icon-mobile fa fa-phone"></i>
          {block.image && (
            <div className="col-lg-8 col-md-12 hero-image">
              <img src={block.image.fullpath} />
            </div>
          )}

          <div className="col-lg-4 col-md-12 hero-block text-right">
            <div className="hero-block-phone">
              <a href="tel:+436644417491">+43 <span className="has--color-orange">|</span> 664 <span className="has--color-orange">|</span> 44 17 491</a>
              <i className="phone-icon fa fa-phone"></i>
            </div>
            <div className="hero-block-text">
              <h1>{block.headline}</h1>
              {block.buttonText &&
                <Link to={block.buttonLink ? block.buttonLink.slug : ''} className="btn hero-block-text-btn">
                  {block.buttonText}
                  <i className="btn-icon fa fa-arrow-right"></i>
                </Link>
              }
            </div>
            <div className="hero-block-scroll">
              Scroll Down
            </div>
            {block.showTeaser && (
              <a href={block.teaserTarget?.absolutePath} className="hero-block-teaser">
                <p className="teaser-label">{block.teaserLabel}</p>
                <p className="teaser-headline">{block.teaserHeadline}</p>
                <p className="teaser-text">{block.teaserText}</p>
              </a>
            )}
          </div>
        </div>
      }
    </>
  )
}
