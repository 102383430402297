import React, { FC } from 'react'

interface IImage {
  fullpath: string
}

interface IContent {
  headline: string
  text: string
  position: string
  imageOne: IImage
  imageTwo: IImage
  imageThree: IImage
}

export const ThreeImagesContent: FC<{ block: IContent }> = ({ block }) => {
  const offsetTextBlock = block.position === 'right' ? 'offset-lg-6' : block.position === 'left' ? '' : 'offset-lg-3'
  const classesFirstBlock = block.position === 'right' || block.position === 'left'
    ? 'three-images-content-block-md col-lg-6' : 'three-images-content-block-sm col-lg-3'
  const classesSecondBlock = block.position === 'right' || block.position === 'left'
    ? 'three-images-content-block-sm col-lg-3' : 'three-images-content-block-md col-lg-6'
  return (
    <div className={`row no-gutters three-images-content block-${block.position} `}>
      {block.position === 'left' && (
        <div className="three-images-content-block-sm has--bg-dark col-lg-3 order-mb-3 order-lg-0">
          <div className="three-images-content-block-sm-wrapper">
            <h2>
              <span dangerouslySetInnerHTML={{ __html: block.headline.replace('-', '<br/>') }}/>
            </h2>
          </div>
        </div>
      )}
      <div className={`col-mb-6 order-mb-1 order-lg-0 ${classesFirstBlock}`}>
        {block.imageOne &&
          <img src={block.imageOne.fullpath} alt={block.headline} />
        }
      </div>
      {block.position !== 'left' && (
        <div className="three-images-content-block-sm has--bg-dark col-lg-3 col-mb-12 order-mb-3 order-lg-0">
          <div className="three-images-content-block-sm-wrapper">
            <h2>
              <span dangerouslySetInnerHTML={{ __html: block.headline.replace('-', '<br/>') }}/>
            </h2>
          </div>
        </div>
      )}
      <div className={`col-mb-6 order-mb-2 order-lg-0 ${classesSecondBlock} `}>
        {block.imageTwo &&
          <img src={block.imageTwo.fullpath} alt={block.headline} />
        }
      </div>
      <div className="three-images-content-block-xl col-lg-12 order-mb-4 order-lg-0">
        {block.imageThree &&
          <img src={block.imageThree.fullpath} alt={block.headline} />
        }
        <div
          className={`three-images-content-block-xl-text col-lg-3 text-right ${offsetTextBlock}`}
          dangerouslySetInnerHTML={{ __html: block.text }}>
        </div>
      </div>
    </div>
  )
}
