import React, { FC } from 'react'

interface IImage {
  fullpath: string
}

interface IContent {
  headline: string
  text: string
  signature: IImage
  handwritten: IImage
}

export const Paragraph: FC<{ block: IContent }> = ({ block }) => {
  return (
    <div className={`paragraph contentblock-spacer ${block.handwritten ? 'has--handwritten' : ''}`}>
      <div className="container">
        <div className="position-relative">
          <h2 className="paragraph-headline">{block.headline}</h2>
          {block.handwritten &&
            <img className="paragraph-handwritten" src={block.handwritten.fullpath}></img>
          }
        </div>
        <div className="paragraph-text" dangerouslySetInnerHTML={{ __html: block.text }}></div>
        {block.signature &&
          <img className="paragraph-signature" src={block.signature.fullpath}></img>
        }
      </div>
    </div>
  )
}
