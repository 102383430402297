import React, { FC } from 'react'
import { Link } from 'gatsby'

interface IImage {
  fullpath: string
}

interface ILink {
  name: string
  absolutePath: string
}

interface IFooter {
  image: IImage
  email: string
  headline: string
  quicklinks: ILink[]
  phoneNumber: string
  personalImage: IImage
}

export const Footer: FC<{ footer: IFooter }> = ({ footer }) => {
  return (
    <footer className="footer row no-gutters">
      <div className="col-xl-8 col-lg-6 col-md-12 footer-image">
        <img src={footer.image.fullpath} alt={footer.headline} />
      </div>
      <div className="col-xl-4 col-lg-6 col-md-12 footer-block text-right">
        <div className="footer-block-wrapper">
          <h2>{footer.headline}</h2>
          {footer.personalImage && (
            <img className="footer-block-personal-image" src={footer.personalImage.fullpath} alt={footer.headline}/>
          )}
          <div className="col-12 footer-block-contact">
            <span className="footer-block-contact-label">Call me</span>
            <a href={`tel:${footer.phoneNumber.replace(/\s/g, '')}`} className="footer-block-contact-text">{footer.phoneNumber}</a>
          </div>
          <div className="col-12 footer-block-contact">
            <span className="footer-block-contact-label">Text Me</span>
            <a href={'mailto:' + footer.email} className="footer-block-contact-text">{footer.email}</a>
            <span className="footer-block-contact-additional"></span>
          </div>
          <div className="col-12 footer-block-contact-follow-me">
            <span>Follow me</span>
          </div>

          <a rel="noreferrer" target="_blank" href='https://www.instagram.com/benkalra_at/' className="btn footer-block-btn">
            <i className="btn-icon fa fa-instagram"></i>
            benkalra_at
          </a>

          <div className="col-12 footer-quicklink-wrapper">
            {footer.quicklinks.map(item => (
              <Link to={item.absolutePath} className="footer-quicklink" key={item.absolutePath}>
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}
