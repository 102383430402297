import React, { FC, useEffect, useRef } from 'react'

interface IImage {
  fullpath: string
}

interface IContent {
  name: string
  text: string
  images: IImages[]
}

export const Project: FC<{ block: IContent }> = ({ block }) => {
  const projectRef = useRef(null)

  useEffect(() => {
    const setMansory = async () => {
      const Masonry = await import('masonry-layout')
        .then(({ default: item }) => {
          return item
        })
        .catch(console.error)

      setTimeout(function () {
        new Masonry(projectRef.current, {
          itemSelector: '.gallery-item',
          columnWidth: 1
        })
      }, 300)
    }

    setMansory()
  }, [])

  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item)
  }

  const images = block.images
  images.insert(1, [])

  return (
    <div className="project-item gallery container contentblock-spacer">
      <div className="gallery-container">
        <h2 className="paragraph-headline">{block.name}</h2>
        {block.images &&
          <div ref={projectRef} className="gallery-wrapper">
            {images.map((item, i: number) => (
              <div className={`gallery-item is--item-${i} ${getLayoutSizeConfig(i)}`} key={i}>
                {item.image &&
                  <div className="gallery-item__inner">
                    <img src={item.image.fullpath} alt={getAlt(item.image)} />
                  </div>
                }
                {item.image === undefined &&
                  <div className="project-item-info-container gallery-item__inner primary-bg is-fullwidth-mobile paragraph">
                    <h3 className="mt-4 mt-lg-5 mb-3 mb-lg-4 project-item-title paragraph-subheadline">Leistungen</h3>
                    <div className="project-item-description" dangerouslySetInnerHTML={{ __html: block.text }}></div>
                  </div>
                }
              </div>
            ))}

          </div>
        }
      </div>
    </div>
  )
}

function getAlt (image: IImage) {
  if (image.metadata) {
    const item = image.metadata.find(element => element.name === 'alt')

    return item ? item.data : undefined
  }

  return undefined
}

function getLayoutSizeConfig (index: Number) {
  if (index === 1) {
    return 'is--width-1 is--height-2'
  }

  if (index === 2) {
    return 'is--width-1 is--height-1'
  }

  if (index === 3) {
    return 'is--width-1 is--height-1'
  }

  return 'is--width-2 is--height-1-5'
}
