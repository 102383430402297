import React, { FC } from 'react'

interface IImage {
  fullpath: string
}

interface IProduct {
  label: string
  text: string
  image: IImage
}

interface IContent {
  headline: string
  products: IProduct[]
}

export const ProductVariants: FC<{ block: IContent }> = ({ block }) => {
  console.log(block)
  return (
    <div className="product-variants contentblock-spacer">
      <div className="container">
        <h2 className="paragraph-headline mb-lg-5 mb-2">{block.headline}</h2><br/>
        <div className="row">
          {block.products.map((item: IProduct, i: number) => (
            <div className="col-md-6 col-lg-4 paragraph mb-4 mb-lg-0" key={`product${i}`}>
              <img className="img-fluid" src={item.image.fullpath} />
              <h3 className="font-bold paragraph-subheadline mt-lg-5">{item.label}</h3>
              <div className="variant-description" dangerouslySetInnerHTML={{ __html: item.text }}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
