import React, { FC } from 'react'

interface IImage {
  fullpath: string
}

interface IContent {
  image: IImage
  logo: IImage
}

export const ImageWithmarker: FC<{ block: IContent }> = ({ block }) => {
  return (
    <div className="image-with-marker row no-gutters">
      {block.logo &&
        <img src={block.logo.fullpath} className="image-with-marker-logo"/>
      }
      <div className="col-lg-12 image-with-marker-block" style={ block.image && { backgroundImage: `url(${block.image.fullpath})` } }>
        <div className="row no-gutters image-with-marker-block-first-row">
          <div className="col-lg-6 col-md-6 m-md-auto">
            <a target="_blank" href="https://www.fischersports.com/at_de/ranger-115-fr-big-stix-1535?c=485" className="image-with-marker-block-item top-left">
              <div className="image-with-marker-block-item-text">
                <span>Fischer</span>
                <span>RANGER 115 FR - BIG STIX</span>
              </div>
              <div className="image-with-marker-block-item-circle">
                <span className="image-with-marker-block-item-circle-outer">
                  <span className="image-with-marker-block-item-circle-inner"></span>
                </span>
              </div>
            </a>
          </div>
          <div className="col-lg-6 col-md-6 m-md-auto">
            <a href="https://onewaysport.com/" target="_blank" className="image-with-marker-block-item top-right">
              <div className="image-with-marker-block-item-circle">
                <span className="image-with-marker-block-item-circle-outer">
                  <span className="image-with-marker-block-item-circle-inner"></span>
                </span>
              </div>
              <div className="image-with-marker-block-item-text">
                <span>ONEWAY</span>
                <span>SPORT</span>
              </div>
            </a>
          </div>
        </div>
        <div className="row no-gutters image-with-marker-block-second-row">
          <div className="col-lg-6 col-md-6 m-md-auto">
            <a href="https://www.salewa.com/de-at" target="_blank" className="image-with-marker-block-item center-left">
              <div className="image-with-marker-block-item-text order-mb-2 order-lg-0">
                <span>Salewa</span>
                <span>Hose</span>
              </div>
              <div className="image-with-marker-block-item-circle order-mb-1 order-lg-0">
                <span className="image-with-marker-block-item-circle-outer">
                  <span className="image-with-marker-block-item-circle-inner"></span>
                </span>
              </div>
            </a>
            <a href="https://www.salewa.com/de-at" target="_blank" className="image-with-marker-block-item bottom-left">
              <div className="image-with-marker-block-item-text order-mb-2 order-lg-0">
                <span>Salewa</span>
                <span>Jacke</span>
              </div>
              <div className="image-with-marker-block-item-circle order-mb-1 order-lg-0">
                <span className="image-with-marker-block-item-circle-outer">
                  <span className="image-with-marker-block-item-circle-inner"></span>
                </span>
              </div>
            </a>
          </div>
          <div className="col-lg-6 col-md-6 m-md-auto">
            <a href="https://www.fischersports.com/at_de/ranger-130-walk-dyn-1462" target="_blank" className="image-with-marker-block-item bottom-right">
              <div className="image-with-marker-block-item-circle">
                <span className="image-with-marker-block-item-circle-outer">
                  <span className="image-with-marker-block-item-circle-inner"></span>
                </span>
              </div>
              <div className="image-with-marker-block-item-text">
                <span>Fischer</span>
                <span>Ranger 130 Walk DYN</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
