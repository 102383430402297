import React, { FC, useEffect, useRef } from 'react'
import { Link } from 'gatsby'

interface IImage {
  fullpath: string
}

interface ILink {
  absolutePath: string
}

interface IService {
  name: string
  slug: string
  image: IImage
  detailLink: ILink
}

interface IContent {
  services: IService[]
}

export const ServicesOverview: FC<{ block: IContent }> = ({ block }) => {
  const serviceRef = useRef(null)

  useEffect(() => {
    const setMansory = async () => {
      const Masonry = await import('masonry-layout')
        .then(({ default: item }) => {
          return item
        })
        .catch(console.error)

      new Masonry(serviceRef.current, {
        itemSelector: '.gallery-item',
        columnWidth: 1
      })
    }

    setMansory()
  }, [])

  return (
    <div className="services-overview">
      <div className="gallery">
        <div className="gallery-container">
          <div ref={serviceRef} className="gallery-wrapper">
            {block.services.map((service, i) => (
              <div className={`gallery-item has--title is--item-${i} ${getLayoutSizeConfig(i)}`} key={service.slug}>
                <div className="gallery-item__inner">
                  {service.detailLink &&
                  <Link to={service.detailLink.absolutePath}>
                    {service.image &&
                      <img src={service.image.fullpath} alt={service.name} />
                    }
                    <h3 className="gallery-item__title">
                      {service.name}
                    </h3>
                  </Link>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

function getLayoutSizeConfig (index: Number) {
  if (index === 1) {
    return 'is--width-1 is--height-2'
  }

  if (index === 2) {
    return 'is--width-1 is--height-1'
  }

  if (index === 3) {
    return 'is--width-1 is--height-1'
  }

  return 'is--width-2 is--height-1-5'
}
