import { useWindowScroll } from 'react-use'

let lastScrollPosition = 0

export const useScrollDirection = () => {
  const { y } = useWindowScroll()

  const down = y >= lastScrollPosition
  lastScrollPosition = y
  return down
}
