import React, { FC } from 'react'
import { Link } from 'gatsby'

interface IImage {
  fullpath: string
}

interface ILink {
  slug: string
}

interface IContent {
  firstImage: IImage
  firstBrand: IImage
  firstHeadline: string
  firstLink: ILink
  secondImage: IImage
  secondBrand: IImage
  secondHeadline: string
  secondLink: ILink
}

export const Splitpage: FC<{ block: IContent }> = ({ block }) => {
  return (
    <div className="services-overview">
      <div className='row no-gutters'>
        <div className='col-lg-6 col-md-12 theme-splitpage-block has--overlay order-1 order-md-2'>
          {block.firstImage &&
          <img src={block.firstImage.fullpath} alt={block.firstHeadline} className='theme-splitpage-block-image' />
          }
          <div className='col-lg-12 theme-splitpage-block-logo'>
            {block.firstBrand &&
            <img src={block.firstBrand.fullpath} alt={block.firstHeadline} />
            }
          </div>
          <div className='col-lg-12 theme-splitpage-block-text has--bg-gray'>
            <h1>{block.firstHeadline}</h1>
            {block.firstLink &&
            <Link to={block.firstLink.slug} className='btn theme-splitpage-block-btn has--bg-light'>
              Los geht’s!
              <i className="btn-icon fa fa-arrow-right"></i>
            </Link>
            }
          </div>
        </div>
        <div className='col-lg-6 col-md-12 theme-splitpage-block has--overlay order-2 order-md-1'>
          {block.secondImage &&
          <img src={block.secondImage.fullpath} alt={block.secondHeadline} className='theme-splitpage-block-image'/>
          }
          <div className='col-lg-12 theme-splitpage-block-logo'>
            {block.secondBrand &&
            <img src={block.secondBrand.fullpath} alt={block.secondHeadline} />
            }
          </div>
          <div className='col-lg-12 theme-splitpage-block-text has--bg-dark'>
            <h1>{block.secondHeadline}</h1>
            {block.secondLink &&
            <Link to={block.secondLink.slug} className='btn theme-splitpage-block-btn has--bg-orange'>
              Los geht’s!
              <i className="btn-icon fa fa-arrow-right"></i>
            </Link>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
