import React, { FC, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useWindowScroll } from 'react-use'
import { useScrollDirection } from '@hooks/ScrollDirection'
import LogoSkiGuideWhite from '@images/logo-kalra-freeride-white.svg'
import LogoRooferWhite from '@images/logo-kalra-spenglerei-white.svg'

interface INavigationLink {
  id: number
  slug: string
  name: string
  children: INavigationLink[]
  show: boolean
}

export const Navigation: FC<{navigation: any[], language: string}> = ({ navigation, language }) => {
  const { y } = useWindowScroll()
  const [navBurgerState, setNavBurgerState] = useState('')
  const isScrollDown = useScrollDirection()
  const navSorted = navigation.sort((a, b) => a.priority - b.priority)
  const [brandLink, setBrandLink] = useState(false)

  console.log(navigation)

  const stickyClass = () => {
    if (y >= 40 && isScrollDown) {
      return 'is--sticky'
    }

    if (y >= 40) {
      return 'is--sticky is--sticky-up'
    }

    return ''
  }

  const toggleNavigation = () => {
    if (navBurgerState) {
      setNavBurgerState('')
      return
    }

    setNavBurgerState('is--open open')
  }

  useEffect(() => {
    const navigationDepth = document.location.pathname.split('/')

    setBrandLink(navigationDepth.length === 2)
  }, [])

  return (
    <div className={`${stickyClass()} nav-outer`}>
      {!brandLink &&
      <Link to="../" className="hero-logo" title="Logo">
        <img src={LogoSkiGuideWhite} className="theme-skiguide-logo" />
        <img src={LogoRooferWhite} className="theme-roofer-logo" />
      </Link>
      }
      {brandLink &&
      <div className="hero-logo">
        <img src={LogoSkiGuideWhite} className="theme-skiguide-logo" />
        <img src={LogoRooferWhite} className="theme-roofer-logo" />
      </div>
      }
      <nav className="nav-container container-middle">
        <button onClick={toggleNavigation} className={`nav-burger burger burger-squeeze ${navBurgerState}`}>
          <div className="burger-lines"></div>
        </button>
        <div className={`nav-backdrop ${navBurgerState}`}></div>
        <div className={`nav-slide ${navBurgerState}`}>
          <ul className="nav-items">
            {navSorted.map((link: INavigationLink) => (
              <li key={link.id} className={link.show ? 'd-none' : 'nav-item'}>
                <Link
                  className="nav-item-link"
                  to={link.slug}
                  activeClassName="is--active"
                >
                  {link.name}
                </Link>
                {link.children && (
                  <ul className="nav-child-items">
                    {link.children.map((childLink: INavigationLink) => (
                      <li key={childLink.id} className={childLink.show ? 'd-none' : 'nav-child-item'}>
                        <Link
                          className="nav-item-child-link"
                          to={childLink.slug}
                          activeClassName="is--active"
                        >
                          {childLink.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  )
}
