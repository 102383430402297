import * as React from 'react'
import '@scss/main.scss'
import { Layout } from '@components/layout'
import { Navigation } from '@components/Navigation'
import Components from '@components/components'
import { Footer } from '@components/Footer'
import Helmet from 'react-helmet'
import { withPrefix } from 'gatsby'

const ContentPage = (data: any) => {
  return (
    <Layout >
      <Helmet
        htmlAttributes={{
          lang: 'de'
        }}
      >
        <title>
          {`${data.pageContext.data.data.website.getContentPage.seoTitle as string ?? data.pageContext.data.data.website.getContentPage.name} | Benjamin Kalra`}
        </title>
        <meta name="description" content={data.pageContext.data.data.website.getContentPage.seoDescription} />

        {data.pageContext.data.data.website.getContentPage.seoImage &&
          <meta property="og:image" content={data.pageContext.data.data.website.getContentPage.seoImage.fullpath} />
        }
        <meta property="og:title" content={`${data.pageContext.data.data.website.getContentPage.seoTitle as string ?? data.pageContext.data.data.website.getContentPage.name} | Benjamin Kalra`} />
        <meta property="og:description" content={data.pageContext.data.data.website.getContentPage.seoDescription} />

        <link rel="icon" type="image/png" href="https://dnh86dedcvmzt.cloudfront.net/static/favicon-16.png" sizes="16x16" />
        <link rel="icon" type="image/png" href="https://dnh86dedcvmzt.cloudfront.net/static/favicon-32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="https://dnh86dedcvmzt.cloudfront.net/static/favicon-96.png" sizes="96x96" />
        <link rel="apple-touch-icon-precomposed" sizes="152x152" href="https://dnh86dedcvmzt.cloudfront.net/static/touch-apple-152.png" />
        <link rel="apple-touch-icon-precomposed" sizes="167x167" href="https://dnh86dedcvmzt.cloudfront.net/static/touch-apple-167.png" />
        <link rel="apple-touch-icon-precomposed" sizes="180x180" href="https://dnh86dedcvmzt.cloudfront.net/static/touch-apple-180.png" />

        <script src={withPrefix('https://use.fortawesome.com/33478b2a.js')} type="text/javascript" />
      </Helmet>
      <div className={data.pageContext.theme}>
        {data.pageContext &&
          <Navigation navigation={data.pageContext.navigation} language={data.pageContext.language} />
        }
        {data.pageContext.data.data.website.getContentPage.elements?.map((block: any) =>
          Components(block)
        )}
        {data.pageContext.footer &&
          <Footer footer={data.pageContext.footer} />
        }
      </div>
    </Layout>
  )
}

export default ContentPage
