import React, { FC, useEffect, useRef, useState } from 'react'

interface IImage {
  image: IImageData
}

interface IImageData {
  fullpath: string
  metadata: IMetaDataItem[]
}
interface IMetaDataItem {
  name: string
  data: string
}

interface IContent {
  images: IImage[]
}

export const Gallery: FC<{ block: IContent }> = ({ block }) => {
  const galleryRef = useRef(null)

  useEffect(() => {
    const setMansory = async () => {
      const Masonry = await import('masonry-layout')
        .then(({ default: item }) => {
          return item
        })
        .catch(console.error)

      new Masonry(galleryRef.current, {
        itemSelector: '.gallery-item',
        columnWidth: 1
      })
    }

    setMansory()
  }, [])

  return (
    <div className="gallery contentblock-spacer is--simple">
      <div className="gallery-container">
        {block.images &&
          <div ref={galleryRef} className="gallery-wrapper">
            {block.images.map((item, i: number) => (
              <div className={`gallery-item is--item-${i} ${getLayoutSizeConfig(i)}`} key={i}>
                <div className="gallery-item__inner">
                  <img src={item.image.fullpath} alt={getAlt(item.image)} />
                </div>
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  )
}

function getAlt (image: IImageData) {
  if (image.metadata) {
    const item = image.metadata.find(element => element.name === 'alt')

    return item ? item.data : undefined
  }

  return undefined
}

function getLayoutSizeConfig (index: Number) {
  if (index === 1) {
    return 'is--width-1 is--height-2'
  }

  if (index === 2) {
    return 'is--width-1 is--height-1'
  }

  if (index === 3) {
    return 'is--width-1 is--height-1'
  }

  return 'is--width-2 is--height-1-5'
}
