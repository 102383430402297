import React, { FC, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useIntl, Link, FormattedMessage } from 'gatsby-plugin-intl'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import ReactDatePicker, { setDefaultLocale, registerLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
import moment from 'moment'

setDefaultLocale('de', de)
registerLocale('de', de)

interface IForm {
  type: string
  headline: string
}

const SUBMIT_FORM_MUTATION = gql`
  mutation roofFormSubmit($input: RoofFormInput) {
    roofFormSubmit(input: $input)
  }
`

const styleTextarea = {
  minHeight: 174
}

export const RoofService: FC<{ block: IForm }> = ({ block }) => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const intl = useIntl()

  const { register, handleSubmit, control, formState: { errors } } = useForm()
  const onSubmit = (data: any, e) => {
    setFormLoading(true)

    submitForm(data).then((result) => {
      e.target.reset()
      setFormSubmitted(true)
      setFormLoading(false)
    }).catch(() => {
      setFormSubmitted(false)
      setFormLoading(false)
    })
  }

  const [roofServiceSubmit] = useMutation(SUBMIT_FORM_MUTATION)

  async function submitForm (data: any) {
    return await roofServiceSubmit(
      {
        variables: {
          input: {
            company: data.company,
            buildingType: data.buildingType,
            roofSize: data.roofSize,
            roofType: data.roofType.join(', '),
            postcode: data.postcode,
            city: data.city,
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            phoneNumber: data.phone,
            message: data.message
          }
        }
      }
    )
  }

  return (
    <div className="form-overlay primary-bg">
      <div className="container">
        <h2 className="paragraph-headline">{block.headline}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formSubmitted &&
            <div className="alert alert-success">{intl.formatMessage({ id: 'form_thanks_for_inquiry' })}</div>
          }
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="assembling">{intl.formatMessage({ id: 'form_type_of_building' })}</label>
                <div className="form-icon-select">
                  <div className="form-icon-select-entry">
                    <input checked type="radio" id="FAMILY_HOUSE" {...register('buildingType', { required: true })} value="FAMILY_HOUSE" />
                    <label htmlFor="FAMILY_HOUSE"><i className="fa fa-roof-single"></i><span>Einfamilienhaus</span><i className="fa fa-check"></i></label>
                  </div>
                  <div className="form-icon-select-entry">
                    <input type="radio" id="APARTMENT_BUILDING" {...register('buildingType', { required: true })} value="APARTMENT_BUILDING" />
                    <label htmlFor="APARTMENT_BUILDING"><i className="fa fa-roof-double"></i><span>Mehrfamilienhaus</span><i className="fa fa-check"></i></label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="name">{intl.formatMessage({ id: 'form_location_of_object' })}</label>
                <div className="d-flex">
                  <input required {...register('postcode', { required: true })} placeholder={intl.formatMessage({ id: 'form_postcode' })} type="text" className="form-control" id="postcode" />
                  <input required {...register('city', { required: true })} placeholder={intl.formatMessage({ id: 'form_city' })} type="text" className="form-control" id="city" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="roofSize">Größe der Dachfläche</label>
                <select {...register('roofSize', { required: true })} className="form-control">
                  <option value="bis 400 m2">bis 400 m2</option>
                  <option value="ab 400m2">ab 400m2</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="roofType">Bestehende Dachhaut aus</label>
                <select multiple {...register('roofType', { required: true })} className="form-control multiple">
                  <option value="Blechdach">Blechdach</option>
                  <option value="Ziegeldach">Ziegeldach</option>
                  <option value="Pappdach">Pappdach</option>
                  <option value="Foliendach">Foliendach</option>
                  <option value="Schindeldach">Schindeldach</option>
                  <option value="Prefasystem">Prefasystem</option>
                  <option value="Anderes">Anderes</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="email">{intl.formatMessage({ id: 'form_company' })}</label>
                <input {...register('company', { required: false })} type="text" className="form-control" id="company" />
              </div>
              <div className="form-group">
                <label htmlFor="name">{intl.formatMessage({ id: 'form_name' })}</label>
                <div className="d-flex">
                  <input required {...register('firstname', { required: true })} placeholder={intl.formatMessage({ id: 'form_firstname' })} type="text" className="form-control" id="firstName" />
                  <input required {...register('lastname', { required: true })} placeholder={intl.formatMessage({ id: 'form_lastname' })} type="text" className="form-control" id="lastName" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="email">{intl.formatMessage({ id: 'form_email' })}</label>
                <input required {...register('email', { required: true })} placeholder={intl.formatMessage({ id: 'form_email_placeholder' })} type="email" className="form-control" id="email" />
              </div>
              <div className="form-group">
                <label htmlFor="phone">{intl.formatMessage({ id: 'form_phone' })}</label>
                <input required {...register('phone', { required: true })} placeholder={intl.formatMessage({ id: 'form_phone_placeholder' })} type="phone" className="form-control" id="phone" />
              </div>
              <div className="form-group">
                <label htmlFor="name">{intl.formatMessage({ id: 'form_message' })}</label>
                <textarea style={styleTextarea} rows="3" {...register('message', { required: true })} className="form-control" id="message" />
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="row">
                <div className="col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="gdpr" name="gdpr" />
                    <label className="custom-control-label pl-3" htmlFor="gdpr">{intl.formatMessage({ id: 'form_accept_gdpr' })}  <Link className="text-white" to={block.gdpr.absolutePath}>{intl.formatMessage({ id: 'form_gdpr' })}</Link></label>
                  </div>
                </div>
                <div className="col-md-6 text-lg-right mt-3 mt-lg-0">
                  <button disabled={formLoading} className="btn btn-light">{intl.formatMessage({ id: 'form_submit' })}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
