import React from 'react'
import { Hero } from './Hero'
import { Paragraph } from './Paragraph'
import { ServicesOverview } from './ServicesOverview'
import { ImageTextCard } from './ImageTextCard'
import { Project } from './Project'
import { ProductVariants } from './ProductVariants'
import { ProductOrderForm } from './ProductOrderForm'
import { Facts } from './Facts'
import { Gallery } from './Gallery'
import { Quicklinks } from './Quicklinks'
import { ThreeImagesContent } from './ThreeImagesContent'
import { ImageWithmarker } from './ImageWithmarker'
import { Timeline } from './Timeline'
import { Splitpage } from './Splitpage'
import { FormWrapper } from './Form'

const AreaBlocks = {
  Website_fieldcollection_Hero: Hero,
  Website_fieldcollection_Paragraph: Paragraph,
  Website_fieldcollection_ServicesOverview: ServicesOverview,
  Website_fieldcollection_ImageTextCard: ImageTextCard,
  Website_fieldcollection_Project: Project,
  Website_fieldcollection_ProductVariants: ProductVariants,
  Website_fieldcollection_ProductOrderForm: ProductOrderForm,
  Website_fieldcollection_Facts: Facts,
  Website_fieldcollection_Gallery: Gallery,
  Website_fieldcollection_Quicklinks: Quicklinks,
  Website_fieldcollection_ThreeImagesContent: ThreeImagesContent,
  Website_fieldcollection_ImageWithMarker: ImageWithmarker,
  Website_fieldcollection_Splitpage: Splitpage,
  Website_fieldcollection_Timeline: Timeline,
  Website_fieldcollection_InquiryForm: FormWrapper
}

/* eslint-disable */
export default block => {
  if (typeof AreaBlocks[block.__typename] !== 'undefined') {
    return React.createElement(AreaBlocks[block.__typename], {
      key: block.__typename + Math.random(),
      block: block
    })
  }
  return React.createElement(
    () => <div>The component "{block.__typename}" has not been created yet.</div>,
    { key: block.__typename }
  )
}
/* eslint-enable */
