import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery, useMutation } from '@apollo/client'
import { useIntl, Link, FormattedMessage } from 'gatsby-plugin-intl'
import gql from 'graphql-tag'

interface IForm {
  type: string
  headline: string
}

const SUBMIT_FORM_MUTATION = gql`
  mutation ContactForm($input: ContactFormInput) {
    contactFormSubmit(input: $input)
  }
`

export const InquiryForm: FC<{ block: IForm }> = ({ block }) => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const intl = useIntl()

  const { register, handleSubmit, watch, formState: { errors } } = useForm()
  const onSubmit = (data: any, e) => {
    setFormLoading(true)
    submitForm(data).then((result) => {
      e.target.reset()
      setFormSubmitted(true)
      setFormLoading(false)
    }).catch(() => {
      setFormSubmitted(false)
      setFormLoading(false)
    })
  }

  const [contactFormSubmit] = useMutation(SUBMIT_FORM_MUTATION)

  async function submitForm (data: any) {
    return await contactFormSubmit(
      { variables: { input: { formType: 'ROOFER', firstname: data.firstname, lastname: data.lastname, email: data.email, phoneNumber: data.phone, message: data.message } } }
    )
  }

  return (
    <div className="form-overlay is--contact-form">
      <div className="container">
        <h2 className="paragraph-headline">{block.headline}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formSubmitted &&
            <div className="alert alert-success">Vielen Dank für Ihre Anfrage.</div>
          }
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">{ intl.formatMessage({ id: 'form_name' })}</label>
                <div className="d-flex">
                  <input required {...register('firstname', { required: true })} placeholder={ intl.formatMessage({ id: 'form_firstname' })} type="text" className="form-control" id="firstName" />
                  <input required {...register('lastname', { required: true })} placeholder={ intl.formatMessage({ id: 'form_lastname' })} type="text" className="form-control" id="lastName" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="email">E-Mail Adresse</label>
                <input required {...register('email', { required: true })} placeholder="max@mustermann.com" type="email" className="form-control" id="email" />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Telefonnummer</label>
                <input required {...register('phone', { required: true })} placeholder="+43 660 12345678" type="phone" className="form-control" id="phone" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">Ihre Nachricht</label>
                <textarea rows="6" {...register('message', { required: true })} className="form-control" id="message" />
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group form-check">
                    <input required type="checkbox" className="form-check-input" id="gdpr" />
                    <label className="form-check-label pl-3" htmlFor="gdpr">{ intl.formatMessage({ id: 'form_accept_gdpr' })}  <Link className="text-white" to={block.gdpr.absolutePath}>{ intl.formatMessage({ id: 'form_gdpr' })}</Link></label>
                  </div>
                </div>
                <div className="col-md-6 text-lg-right mt-3 mt-lg-0">
                  <button disabled={formLoading} className="btn btn-light">{intl.formatMessage({ id: 'form_submit' })}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
