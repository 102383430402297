import React, { FC } from 'react'

interface IFact {
  label: string
  text: string
  icon: string
}

interface IContent {
  headline: string
  facts: IFact
}

export const Facts: FC<{ block: IContent }> = ({ block }) => {
  return (
    <div className="fact contentblock-spacer text-white paragraph">
      <div className="container">
        <h2 className="paragraph-headline">{block.headline}</h2>
        <div className="row fact-row">
          {block.facts.map((item: IFact, index: number) => (
            <div className="col-md-3 col-xl-3 fact-element" key={`fact-element-${index}`}>
              <div className="fact-element-icon ">
                <i className={`fa ${item.icon}`} />
              </div>
              <h3 className="fact-element-headline">{item.label}</h3>
              <div className="fact-element-text" dangerouslySetInnerHTML={{ __html: item.text }}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
