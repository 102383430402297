import React, { FC } from 'react'

export const Layout: FC = ({ children }) => {
  return (
    <div>
      <main className="content">
        {children}
      </main>
    </div>
  )
}
