import React, { FC } from 'react'
import { Link } from 'gatsby'

interface IQuicklinks {
  name: string
  slug: string
  absolutePath: string
}

interface IContent {
  headline: string
  quicklinks: IQuicklinks[]
}

export const Quicklinks: FC<{ block: IContent }> = ({ block }) => {
  return (
    <div className="quicklinks contentblock-spacer">
      <div className="container">
        <h2 className="paragraph-headline">{block.headline}</h2>
        <div className="quicklinks-wrapper">
          {block.quicklinks.map(item => (
            <Link to={item.absolutePath} className="quicklinks-item" key={item.slug}>
              {item.name} <i className="fa fa-arrow-right ml-2"></i>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
