import React, { FC, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useIntl, Link, FormattedMessage } from 'gatsby-plugin-intl'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import ReactDatePicker, { setDefaultLocale, registerLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
import moment from 'moment'

setDefaultLocale('de', de)
registerLocale('de', de)

interface IForm {
  type: string
  headline: string
}

const SUBMIT_FORM_MUTATION = gql`
  mutation Form($input: ProductOrderFormInput) {
    productFormSubmit(input: $input)
  }
`

const styleTextarea = {
  minHeight: 174
}

export const OrderForm: FC<{ block: IForm }> = ({ block }) => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const intl = useIntl()

  const { register, handleSubmit, control, formState: { errors } } = useForm()
  const onSubmit = (data: any, e) => {
    setFormLoading(true)

    submitForm(data).then((result) => {
      e.target.reset()
      setFormSubmitted(true)
      setFormLoading(false)
    }).catch(() => {
      setFormSubmitted(false)
      setFormLoading(false)
    })
  }

  const [productFormSubmit] = useMutation(SUBMIT_FORM_MUTATION)

  async function submitForm (data: any) {
    return await productFormSubmit(
      { variables: { input: { shippingDate: moment(data.shippingDate).format('DD.MM.yyyy'), firstname: data.firstname, lastname: data.lastname, email: data.email, assembling: data.assembling, phoneNumber: data.phone, message: data.message, material: data.material, quantity: parseInt(data.quantity), engraving: data.engraving } } }
    )
  }

  return (
    <div className="form-overlay primary-bg">
      <div className="container">
        <h2 className="paragraph-headline">{block.headline}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formSubmitted &&
            <div className="alert alert-success">{intl.formatMessage({ id: 'form_thanks_for_inquiry' })}</div>
          }
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="quantity">{intl.formatMessage({ id: 'form_roses_amount' })}</label>
                <select {...register('quantity', { required: true })} className="form-control">
                  <option value="1">1 {intl.formatMessage({ id: 'form_rose' })}</option>
                  <option value="2">2 {intl.formatMessage({ id: 'form_roses' })}</option>
                  <option value="3">3 {intl.formatMessage({ id: 'form_roses' })}</option>
                  <option value="4">4 {intl.formatMessage({ id: 'form_roses' })}</option>
                  <option value="5">5 {intl.formatMessage({ id: 'form_roses' })}</option>
                  <option value="6">6 {intl.formatMessage({ id: 'form_roses' })}</option>
                  <option value="7">7 {intl.formatMessage({ id: 'form_roses' })}</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="name">{intl.formatMessage({ id: 'form_material' })}</label>
                <select {...register('material', { required: false })} className="form-control">
                  {block.materials.map((item) => (
                    <option key={item.name} value={item.name}>{item.name}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="assembling">{intl.formatMessage({ id: 'form_assembling_roses' })}</label>
                <div className="form-icon-select">
                  <div className="form-icon-select-entry">
                    <input checked type="radio" id="single" {...register('assembling', { required: true })} value="SINGLE" />
                    <label htmlFor="single"><i className="fa fa-rose-single"></i><span>Einzeln</span><i className="fa fa-check"></i></label>
                  </div>
                  <div className="form-icon-select-entry">
                    <input type="radio" id="bunch" {...register('assembling', { required: true })} value="BUNCH" />
                    <label htmlFor="bunch"><i className="fa fa-rose-treplex"></i><span>Strauß</span><i className="fa fa-check"></i></label>
                  </div>
                </div>

              </div>
              <div className="form-group">
                <label htmlFor="engraving">{intl.formatMessage({ id: 'form_engraving' })}</label>
                <input {...register('engraving', { required: false })} placeholder={intl.formatMessage({ id: 'form_engraving_placeholder' })} type="text" className="form-control" />
              </div>

            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="shippingDate">{intl.formatMessage({ id: 'form_shipping_date' })}</label>
                <input required {...register('shippingDate', { required: true })} placeholder={moment().add(3, 'weeks').format('DD.MM.yyyy')} type="date" min={moment().add(3, 'weeks').format('yyyy-MM-DD')} className="form-control" id="shippingDate" />
              </div>
              <div className="form-group">
                <label htmlFor="name">{intl.formatMessage({ id: 'form_name' })}</label>
                <div className="d-flex">
                  <input required {...register('firstname', { required: true })} placeholder={intl.formatMessage({ id: 'form_firstname' })} type="text" className="form-control" id="firstName" />
                  <input required {...register('lastname', { required: true })} placeholder={intl.formatMessage({ id: 'form_lastname' })} type="text" className="form-control" id="lastName" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="email">{intl.formatMessage({ id: 'form_email' })}</label>
                <input required {...register('email', { required: true })} placeholder={intl.formatMessage({ id: 'form_email_placeholder' })} type="email" className="form-control" id="email" />
              </div>
              <div className="form-group">
                <label htmlFor="phone">{intl.formatMessage({ id: 'form_phone' })}</label>
                <input required {...register('phone', { required: true })} placeholder={intl.formatMessage({ id: 'form_phone_placeholder' })} type="phone" className="form-control" id="phone" />
              </div>
              <div className="form-group">
                <label htmlFor="name">{intl.formatMessage({ id: 'form_message' })}</label>
                <textarea style={styleTextarea} rows="3" {...register('message', { required: true })} className="form-control" id="message" />
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group form-check">
                    <input required type="checkbox" className="form-check-input" id="gdpr" />
                    <label className="form-check-label pl-3" htmlFor="gdpr">{intl.formatMessage({ id: 'form_accept_gdpr' })}  <Link className="text-white" to={block.gdpr.absolutePath}>{intl.formatMessage({ id: 'form_gdpr' })}</Link></label>
                  </div>
                </div>
                <div className="col-md-6 text-lg-right mt-3 mt-lg-0">
                  <button disabled={formLoading} className="btn btn-light">{intl.formatMessage({ id: 'form_submit' })}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
