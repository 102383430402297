import React, { FC, useState } from 'react'
import { InquiryForm } from './inquiry'
import { OrderForm } from './order'
import { RoofService } from './roofservice'

interface IForm {
  formType: string
  headline: string
}

export const FormWrapper: FC<{ block: IForm }> = ({ block }) => {
  console.log('form', block)
  return (
    <div>
      {(block.formType === 'skiguide' || block.formType === 'roofer') &&
        <InquiryForm block={block} />
      }
      {block.formType === 'roses' &&
        <OrderForm block={block} />
      }
      {block.formType === 'roofService' &&
        <RoofService block={block} />
      }
    </div>
  )
}
