import React, { FC } from 'react'

interface IContent {
  headline: string
}

export const ProductOrderForm: FC<{ block: IContent }> = ({ block }) => {
  return (
    <div className="product-order-from">
      {block.headline}<br/>
      <hr/>
    </div>
  )
}
