import React, { FC } from 'react'

interface ITimelineEntry {
  headline: string
  label: string
  year: string
}

interface IContent {
  items: ITimelineEntry[]
}

export const Timeline: FC<{ block: IContent }> = ({ block }) => {
  console.log('timeline', block)
  return (
    <div className="contentblock-spacer">
      <div className="container">
        <div className="row timeline-row">
          {block.items.map((item: ITimelineEntry, index: number) => (
            <div className="col-md-3 col-xl-3 timeline-element" key={`timeline-element-${index}`}>
              <div className="timeline-element-year ">
                {item.year}
              </div>
              <h3 className="timeline-element-headline">{item.headline}</h3>
              <div className="timeline-element-text">{item.label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
